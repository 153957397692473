.search_sec1 {
  padding-top: 11.355vw;
  .input_wrapper {
    position: relative;
    border: 0.5px solid #2b2a29;
    border-radius: 0.52vw;
    // padding: 0.865vw 1.146vw;
    padding: 0 1.146vw;
    .input_field {
      background-color: transparent;
      color: #2b2a29;
      font-size: 0.83vw;
      font-family: "Gotham", sans-serif;
      font-weight: 400;
      padding: 0.941vw 0 0.941vw 1.565vw;
      &:focus-visible {
        outline: none;
      }
    }
    .icon_wrapper {
      position: absolute;
      left: initial;
      top: 50%;
      transform: translateY(-50%);
      .search_icon {
        color: #2b2a29;
        width: 100%;
        max-width: 1.04vw;
      }
    }
  }
  .result_text {
    color: #2b2a29;
    font-size: 0.94vw;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 1.5;
    margin-top: 1.67vw;
  }
}

.search_sec2 {
  .product_row {
    // border-top: 1px solid rgba($color: #707070, $alpha: 0.4);
    .product_col {
      padding: 1.25vw 1.15vw;
      border: 1px solid rgba($color: #707070, $alpha: 0.4);
      .text_nowrap {
        color: #2b2a29;
        font-family: "Poppins", sans-serif;
        font-size: 0.89vw;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 1.3;
        margin-bottom: 0.525vw;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .search_sec1 {
    padding-top: 14vw;
    .input_wrapper {
      .input_field {
        font-size: 1.41vw;
        padding: 0.941vw 0 0.941vw 2.15vw;
      }
      .icon_wrapper {
        .search_icon {
          max-width: 1.5vw;
        }
      }
    }
    .result_text {
      font-size: 1.41vw;
    }
  }

  .search_sec2 {
    .product_row {
      .product_col {
        .text_nowrap {
          font-size: 1.37vw;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .search_sec1 {
    padding-top: 28vw;
    .input_wrapper {
      padding: 0 3.2vw;
      .input_field {
        font-size: 4.27vw;
        padding: 2.14vw 0 2.14vw 6vw;
      }
      .icon_wrapper {
        .search_icon {
          max-width: 4.5em;
          margin-bottom: 0.5vw;
        }
      }
    }
    .result_text {
      font-size: 4.8vw;
      margin-top: 5.33vw;
    }
  }

  .search_sec2 {
    .product_row {
      .product_col {
        padding: 6.93vw;
        .text_nowrap {
          font-size: 4.8vw;
          margin-bottom: 2.67vw;
        }
      }
    }
  }
}
